import { RMGridItem } from './RMGridItem';
import { RMGridRoot } from './RMGridRoot';

export type { RMGridItemProps } from './RMGridItem';
export type { RMGridRootProps } from './RMGridRoot';

export const RMGrid = {
  Root: RMGridRoot,
  Item: RMGridItem,
} as const;
