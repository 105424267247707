import { ReactNode } from 'react';

import { GridItem } from './RMGridItem.styles';

export interface RMGridItemProps {
  columns: number;
  children: ReactNode;
}

export function RMGridItem({ columns, children }: RMGridItemProps) {
  return <GridItem columns={columns}>{children}</GridItem>;
}
