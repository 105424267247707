import { ReactNode } from 'react';

import { Spacings } from '@/styles/base/spacings';

import { Grid } from './RMGridRoot.styles';

export interface RMGridRootProps {
  spacing: keyof typeof Spacings;
  children: ReactNode;
}

export function RMGridRoot({ spacing, children }: RMGridRootProps) {
  return <Grid spacing={spacing}>{children}</Grid>;
}
