import { styled } from '@linaria/react';

import { Spacings } from '@/styles/base/spacings';

export const Grid = styled.div<{ spacing: keyof typeof Spacings }>`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(12, 1fr);
  gap: ${(props) => `var(--spacing-${props.spacing})`};
`;
